<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10" v-if="!wasSent">
                <h1 class="mt-5">{{$t('welcome.heading')}}</h1>

                <i18n path="welcome.content" tag="p" class="font-weight-bold text-primary text-bigger">
                    <a :href="`mailto:${$t('welcome.email')}`" class="text-underline" target="_blank">{{ $t('welcome.email') }}</a>
                </i18n>

                <aspena-btn
                    size="lg"
                    variant="aspena-orange"
                    class="mt-4"
                    :to="{ name: 'typeSelection' }"
                >
                    <span class="iconify" data-icon="mdi:arrow-right"></span> {{$t('welcome.start')}}
                </aspena-btn>
            </div>
            <div class="col-12 col-lg-10" v-else>
                <div class="my-5 alert alert-primary text-center">
                    {{$t('welcome.thankYou')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexPage',
    props: {
        wasSent: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
